
              @import "@/assets/css/variables.scss";
            






































































.activity_item {
  background: $white;
  border: 1px solid #F3F4FE;
  border-radius: 8px;
  padding: 16px;
  position: relative;
  box-shadow: 0px 0px 8px 1px rgba(122, 134, 153, 0.15);
  transition: all 0.3s linear;
  cursor: pointer;
  margin-top: 16px;

  &:hover {
    box-shadow: 0px 8px 20px rgba(14, 18, 54, 0.2);
    transform: translate3d(0, -8px, 0);
  }

  .activity_status {
    font-size: 12px;
    padding: 2px 4px 2px 8px;
    position: absolute;
    top: 8px;
    @include rtl-sass-prop(right, left, 0);
    border-radius: 8px 0 0 8px;
    z-index: 3;
  }

  .bg_green {
    background-color: #bbebc6;
    color: #68c39d;
  }

  .bg_yellow {
    background-color: #ffdd9b;
    color: #ef9f05;
  }

  .bg_gray {
    background-color: #dfe4eb;
    color: #8a9099;
  }

  .activity_img {
    width: 100%;
    height: 146px;

    .image-slot {
      height: 100%;
    }

    img {
      border-radius: 8px;
      width: 100%;
      height: 100%;
    }
  }

  .apply_btn {
    margin-top: 8px;
    font-size: 12px;
    display: flex;
    justify-content: flex-end;
  }
}
