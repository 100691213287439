
              @import "@/assets/css/variables.scss";
            



























































.activity_wrapper {
  .title {
    font-size: 16px;
    color: $text-primary;
    line-height: 24px;
    font-weight: 700;
    margin-bottom: 16px;
  }

  .el-alert--warning {
    .el-alert__description {
      margin: 0;
    }
  }
}
